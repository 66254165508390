import Vue, { set } from 'vue'
import Vuex, { Store } from 'vuex'
import moment from 'moment'
import { kebabCase } from 'lodash'
import snackbar from './snackbar'
import cube from './cube'
import booking from './booking'
import page from './page'
import map from './map'
import marklist from './marklist'
import cookieConsent from './cookie-consent'
import Parse, { normalize } from '@/plugins/parse'
import { wait } from '@/utils/helpers'
import { pluralize } from '@/filters/numbers'
let onlineTimeout

Vue.use(Vuex)

function getEnums() {
  return JSON.parse(window.localStorage.getItem('enums') || '{}')
}

// state
export const state = () => ({
  today: null,
  title: null,
  user: normalize(Parse.User.current()),
  initialized: false,
  users: {},
  companies: {},
  mediae: {},
  dictionary: {},
  counts: {},
  routeKey: (new Date()).getTime()
})

// getters
export const getters = {
  app: () => window.localStorage.getItem('app'),
  systemErrorsSummary: (state, getters) => {
    if (!getters.isIntern) { return }
    const errors = []
    const { skippedNumbers, unsyncedLexDocuments, duplicateInvoiceIds } = state.dictionary.systemStatus || {}
    if (skippedNumbers?.length) {
      errors.push(`Übersprungen Belegnummer: ${skippedNumbers.join(', ')}`)
    }
    if (unsyncedLexDocuments) {
      errors.push(`Es gibt ${pluralize(unsyncedLexDocuments, 'Beleg', 'Belege')} in LexOffice, aber nicht in WaWi`)
    }
    if (duplicateInvoiceIds?.length) {
      errors.push(`Duplizierte Rechnungen: ${duplicateInvoiceIds.join(', ')}`)
    }
    return errors.length ? errors.join('\n') : null
  },
  isAdmin: state => state.user?.accType === 'admin',
  isIntern: state => ['admin', 'intern'].includes(state.user?.accType),
  isScout: state => state.user?.accType === 'scout',
  isPartner: state => state.user?.accType === 'partner' && state.user.company,
  isBookingsManager: (state, getters) => state.user?.permissions?.includes('manage-bookings'),
  isFieldworkManager: (state, getters) => state.user?.permissions?.includes('manage-fieldwork'),
  isFrameManager: (state, getters) => state.user?.permissions?.includes('manage-frames'),
  isScoutManager: (state, getters) => state.user?.permissions?.includes('manage-scouts'),
  isScoutOrManager: (state, getters) => getters.isScout || getters.isScoutManager,
  canScout: (state, getters) => getters.isScout || state.user?.permissions?.includes('scout'),
  canManageTaskList: (state, getters) => (taskList) => {
    if (getters.isFieldworkManager) {
      return true
    }
    if (getters.isScoutManager) {
      return taskList.manager?.objectId === state.user.objectId
    }
    return false
  },
  canEditCubePhoto: (state, getters) => (photo) => {
    if (getters.isIntern) {
      return true
    }
    if (getters.isPartner && getters.isScoutManager && state.user.company) {
      if (state.user.company.objectId === state.users[photo.createdBy?.objectId]?.company?.objectId) {
        return true
      }
    }
    return state.user.objectId === photo.createdBy?.objectId
  },
  can: (state, getters) => (item, action) => {
    if (!state.user) { return false }
    if (getters.isIntern) { return true }
    if (item === 'cube') {
      if (action === 'scout') {
        return getters.canScout
      }
      return false
    }
    return false
  },
  users: state => Object.values(state.users)
    .filter(user => !user.isBanned)
    .sort((a, b) => a.fullname > b.fullname ? 1 : -1),
  internUsers: (state, getters) => {
    return getters.users.filter(user => ['admin', 'intern'].includes(user.accType))
  },
  scouts: (state, getters) => (managerId = null, userCompanyId = null) => {
    const scouts = getters.users.filter(user => user.accType === 'scout' || user.permissions?.includes('scout'))
    userCompanyId = userCompanyId || state.user.company?.objectId
    if (userCompanyId) {
      if (userCompanyId === 'intern') {
        return scouts.filter(scout => !scout.company?.objectId)
      }
      return scouts.filter(scout => scout.company?.objectId === userCompanyId)
    }
    if (managerId) {
      const managerCompanyId = state.users[managerId]?.company?.objectId
      // custom fix just for MA Lionsgroup - they see only their scouts and other don't see their scouts
      return managerCompanyId === '4EBkZmBra0'
        ? scouts.filter(scout => scout.company?.objectId === '4EBkZmBra0')
        // temporary fix for dagmar
        : scouts.filter(scout => scout.company?.objectId !== '4EBkZmBra0')
    }
    return scouts
  },
  onlineUsersCount: (state, getters) => getters.users.filter(user => user.isOnline).length || 1,
  managers: (state, getters) => getters.users.filter(user => user.permissions?.includes('manage-scouts')),
  mediae: state => Object.values(state.mediae),
  companies: state => Object.values(state.companies).sort((a, b) => a.name > b.name ? 1 : -1),
  companyByName: (state, getters) => name => getters.companies.find(company => company.name === name),
  contacts: state => [],
  accTypes: (state) => {
    const items = []
    const { accTypes } = state.dictionary || {}
    for (const key of Object.keys(accTypes)) {
      items.push({ value: key, text: accTypes[key] })
    }
    return items
  },
  // dictionary
  cubeStatuses(state) {
    const items = []
    const cubeStatuses = state.dictionary.cubeStatuses || {}
    for (const key of Object.keys(cubeStatuses)) {
      items.push({ value: key, text: cubeStatuses[key] })
    }
    return items
  },
  tags: state => Object.values(state.dictionary.tags || {}),
  housingType: state => objectId => state.dictionary.housingTypes[objectId],
  housingTypes: state => Object.values(state.dictionary.housingTypes || {}),
  housingTypesByLessor: (state, getters) => (lc) => {
    if (lc === 'TLK') {
      return getters.housingTypes.filter(ht => ht.code.startsWith('KVZ') || ht.code.startsWith('MFG'))
    }
    if (lc === 'SGSW') {
      return getters.housingTypes.filter(ht => ht.code.startsWith('SG') || ht.code.startsWith('Netzstation') || ht.code === 'KVZ 59')
    }
    if (lc === 'TBS') {
      return getters.housingTypes.filter(ht => ht.code.startsWith('SG') || ht.code === 'KVZ 59')
    }
    return getters.housingTypes
  },
  housingTypesWithMedia: (state, getters) => [
    { objectId: 'KVZ', code: 'KVZ (Alle)' },
    { objectId: 'MFG', code: 'MFG (Alle)' },
    ...getters.housingTypes,
    { objectId: 'htNM', code: 'Nicht vermarktbar' }
  ],
  states: state => Object.values(state.dictionary.states || {}).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1),
  countries(state) {
    const countries = []
    const dict = state.dictionary.countries || {}
    for (const value of Object.keys(dict)) {
      countries.push({ value, text: dict[value] })
    }
    return countries
  },
  cubeFlags(state) {
    return Object.values(state.dictionary.cubeFlags || {})
  },
  cubeFlagKeys(state) {
    return Object.keys(state.dictionary.cubeFlags || {})
  },
  errorFlagKeys(state, getters) {
    return getters.cubeFlags
      .filter(flag => flag.level === 'error')
      .map(flag => flag.value)
  },
  warningFlagKeys(state, getters) {
    return getters.cubeFlags
      .filter(flag => flag.level === 'warning')
      .map(flag => flag.value)
  },
  printPackageTypes(state) {
    const types = []
    const printPackageTypes = state.dictionary.printPackageTypes || {}
    for (const type of Object.keys(printPackageTypes)) {
      types.push({
        value: type,
        text: printPackageTypes[type]
      })
    }
    return types
  },
  printPackageFaces(state) {
    const faces = []
    const printPackageFaces = state.dictionary.printPackageFaces || {}
    for (const type of Object.keys(printPackageFaces)) {
      faces.push({
        value: type,
        text: printPackageFaces[type]
      })
    }
    return faces
  },
  printPackageFiles(state) {
    const { printPackageTypes, printPackageFaces } = state.dictionary
    return (state.dictionary.printPackageFiles || {}).reduce((acc, key) => {
      const [type, face] = kebabCase(key).split('-')
      acc[key] = `${printPackageTypes[type]} ${printPackageFaces[face]}`
      return acc
    }, {})
  },
  printPackagePriceRanges(state) {
    const aluAndFoil = state.dictionary.printPackages.filter(({ type }) => ['alu', 'foil'].includes(type))
    const kvzPrices = aluAndFoil
      .filter(({ media }) => !media || media === 'KVZ')
      .map(({ price }) => price)
    const mfgPrices = aluAndFoil
      .filter(({ media }) => !media || media === 'MFG')
      .map(({ price }) => price)
    return {
      KVZ: { min: Math.min(...kvzPrices), max: Math.max(...kvzPrices) },
      MFG: { min: Math.min(...mfgPrices), max: Math.max(...mfgPrices) },
      all: { min: Math.min(...kvzPrices, ...mfgPrices), max: Math.max(...kvzPrices, ...mfgPrices) }
    }
  },
  invoiceTypes(state) {
    const items = []
    const invoiceTypes = state.dictionary.invoiceTypes || {}
    for (const key of Object.keys(invoiceTypes)) {
      items.push({ value: key, text: invoiceTypes[key] })
    }
    return items
  },
  invoiceStatuses(state) {
    const items = []
    const invoiceStatuses = state.dictionary.invoiceStatuses || {}
    for (const key of Object.keys(invoiceStatuses)) {
      items.push({ value: key, text: invoiceStatuses[key] })
    }
    return items
  },
  creditNoteStatuses(state) {
    const items = []
    const creditNoteStatuses = state.dictionary.creditNoteStatuses || {}
    for (const key of Object.keys(creditNoteStatuses)) {
      items.push({ value: key, text: creditNoteStatuses[key] })
    }
    return items
  },
  billingCycles(state) {
    const items = []
    const billingCycles = state.dictionary.billingCycles || {}
    for (const key of Object.keys(billingCycles)) {
      items.push({ value: key, text: billingCycles[key] })
    }
    return items
  },
  lessorPercentMap(state) {
    const lessors = Object.values(state.companies).filter(({ lessorCode }) => lessorCode)
    const map = {}
    for (const { lessorCode, lessorPercent } of lessors) {
      map[lessorCode] = lessorPercent || 24
    }
    return map
  },
  gradualPriceMaps(state) {
    const map = {}
    for (const gpm of state.dictionary.gradualPriceMaps || {}) {
      map[gpm.objectId] = gpm
    }
    return map
  },
  taskListStatuses(state, getters) {
    const dict = state.dictionary.taskListStatuses || {}
    if (getters.isPartner) {
      dict[1] = 'Offen'
    }
    return dict
  }
}

// mutations
export const mutations = {
  SET_TITLE(state, title) {
    state.title = title
  },
  SET_CURRENT_USER(state, user) {
    state.user = user
  },
  SET_USER(state, user) {
    set(state.users, user.id, normalize(user))
  },
  SET_TODAY(state, today) {
    state.today = today
  },
  SET_USERS(state, users) {
    for (const user of Object.values(users)) {
      user.isOnline = user.lastOnlineAt && moment()
        .subtract(2, 'minutes')
        .isBefore(user.lastOnlineAt)
      set(state.users, user.objectId, user)
    }
  },
  UNSET_USERS(state) {
    for (const user of state.users) {
      Vue.delete(state.users, user.id)
    }
  },
  SET_COMPANIES(state, companies) {
    for (const company of companies) {
      set(state.companies, company.objectId, company)
    }
  },
  UNSET_COMPANIES(state) {
    for (const company of state.companies) {
      Vue.delete(state.companies, company.id)
    }
  },
  SET_MEDIAE(state, mediae) {
    for (const media of mediae) {
      set(state.mediae, media.no, media)
    }
  },
  UNSET_MEDIAE(state) {
    for (const media of state.mediae) {
      Vue.delete(state.mediae, media.id)
    }
  },
  SET_DICTIONARY(state, dict) {
    for (const key of Object.keys(dict)) {
      set(state.dictionary, key, dict[key])
    }
  },
  SET_COUNTS(state, counts) {
    for (const key of Object.keys(counts)) {
      set(state.counts, key, counts[key])
    }
  },
  UNSET_DICTIONARY(state) {
    for (const key of Object.keys(state.dictionary)) {
      Vue.delete(state.dictionary, key)
    }
  },
  SET_INITIALIZED(state, initialized) {
    state.initialized = initialized
      ? (new Date()).getTime()
      : false
  },
  REFRESH_ROUTE_KEY(state) {
    state.routeKey = (new Date()).getTime()
  }
}

// actions
export const actions = {
  setTitle({ commit }, title) {
    commit('SET_TITLE', title)
  },
  setCurrentUser({ commit }) {
    const user = normalize(Parse.User.current())
    commit('SET_CURRENT_USER', user)
  },
  async initializePublic({ commit, dispatch }) {
    const {
      today,
      ...dict
    } = await Parse.Cloud.run('init').then(normalize)
    today && commit('SET_TODAY', today)
    dict && commit('SET_DICTIONARY', dict)
    // check version, if different to local storage, refetch enums
    let enums = getEnums()
    if (dict.mode !== 'production' || dict.version !== enums.version) {
      enums = await dispatch('fetchEnums')
    }
    commit('SET_DICTIONARY', enums)
    commit('SET_INITIALIZED', true)
  },
  async initialize({ state, commit, dispatch }, sessionToken) {
    sessionToken ||= state.user?.sessionToken
    if (!sessionToken) { return dispatch('logout') }
    try {
      const {
        today,
        users,
        companies,
        mediae,
        ...dict
      } = await Parse.Cloud.run('init', null, { sessionToken })
        .then(normalize)
      today && commit('SET_TODAY', today)
      users && commit('SET_USERS', users)
      companies && commit('SET_COMPANIES', companies)
      mediae && commit('SET_MEDIAE', mediae)
      dict && commit('SET_DICTIONARY', dict)

      // check version, if different to local storage, refetch enums
      let enums = getEnums()
      if (dict.mode !== 'production' || dict.version !== enums.version) {
        enums = await dispatch('fetchEnums', sessionToken)
      }
      commit('SET_DICTIONARY', enums)
      commit('SET_INITIALIZED', true)
      if (state.user) {
        dispatch('setOnlineAt')
        dispatch('fetchCounts')
      }
    } catch (error) {
      if (error.message === 'Lexoffice unreachable') {
        alert('Aufgrund von Wartungsarbeiten ist lexoffice nicht verfügbar. WaWi ist unbenutzbar. Bitte versuchen Sie es später erneut. (https://app.lexoffice.de/)')
        return dispatch('logout')
      }
      if (!error.message.text) {
        if (error.code === Parse.Error.INVALID_SESSION_TOKEN) {
          return dispatch('logout')
        }
        await wait(1000)
        return dispatch('initialize')
      }
      if (error.message.data === 'lexoffice is currently undergoing maintenance') {
        alert('Aufgrund von Wartungsarbeiten ist lexoffice nicht verfügbar. WaWi ist unbenutzbar. Bitte versuchen Sie es später erneut. (https://app.lexoffice.de/)')
      } else {
        alert('WaWi könnte nicht gestartet werden. Bitte versuchen Sie es später erneut. (' + error.message.text + ')')
      }
      dispatch('logout')
    }
  },
  async fetchEnums({ commit }, sessionToken) {
    const enums = await Parse.Cloud.run('enums', null, { sessionToken })
    localStorage.setItem('enums', JSON.stringify(enums))
    return enums
  },
  async fetchCounts({ getters, commit, dispatch }) {
    if (!getters.isIntern) { return }
    const counts = await Parse.Cloud.run('counts')
    commit('SET_COUNTS', counts)
    setTimeout(() => dispatch('fetchCounts'), 1000 * 60 * 5)
  },
  async setOnlineAt({ dispatch }) {
    clearTimeout(onlineTimeout)
    await Parse.Cloud.run('user-last-online-at')
    onlineTimeout = setTimeout(() => dispatch('setOnlineAt'), 60 * 1000)
  },
  async reinitialize({ commit }, keys) {
    const {
      users,
      companies,
      mediae,
      today,
      ...dict
    } = await Parse.Cloud.run('init', { keys }).then(normalize)
    today && commit('SET_TODAY', today)
    users && commit('SET_USERS', users)
    companies && commit('SET_COMPANIES', companies)
    mediae && commit('SET_MEDIAE', mediae)
    commit('SET_DICTIONARY', dict)
    commit('SET_INITIALIZED', true)
  },
  setInitialized({ commit }, initialized) {
    commit('SET_INITIALIZED', initialized)
    if (!initialized) {
      commit('UNSET_USERS')
      commit('UNSET_COMPANIES')
      commit('UNSET_CONTACTS')
      commit('UNSET_MEDIAE')
      commit('UNSET_DICTIONARY')
    }
  },
  async logout() {
    document.getElementsByTagName('html')[0].classList.add('loading')
    await Parse.User.logOut().catch(() => {})
    window.location.href = '/'
  },
  updateUser({ commit }, user) {
    commit('SET_USER', user)
  },
  refreshRoute({ commit }) {
    commit('REFRESH_ROUTE_KEY')
  }
}

const store = new Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    cube,
    booking,
    page,
    map,
    marklist,
    cookieConsent,
    snackbar
  }
})

export default store
